// src/components/HeroSection.js
import React, { useEffect, useRef, useState } from "react";
import starImage from "../../assets/img/64da982b675c905fb3365a18_swish-stars.svg fill.png";
import "animate.css";
import Hero from "../../assets/img/offer-bg.png";

import offer_1 from "../../assets/img/offer-1.png";
import offer_2 from "../../assets/img/offer-2.png";
import offer_3 from "../../assets/img/offer-3.png";
import offer_4 from "../../assets/img/offer-4.png";
import offer_5 from "../../assets/img/offer-5.png";

import { Link } from "react-router-dom";

const HeroSection = () => {
  const [isVisible, setIsVisible] = useState(false);
  const sectionRef = useRef(null);

  useEffect(() => {
    const observer = new IntersectionObserver(
      (entries) => {
        entries.forEach((entry) => {
          if (entry.isIntersecting) {
            setIsVisible(true);
          } else {
            setIsVisible(false); // Set to false when the section is not visible
          }
        });
      },
      { threshold: 0.1 } // Adjust the threshold as needed
    );

    if (sectionRef.current) {
      observer.observe(sectionRef.current);
    }

    return () => {
      if (sectionRef.current) {
        observer.unobserve(sectionRef.current);
      }
    };
  }, []);

  return (
    <div className="container py-5">
      <div ref={sectionRef}>
        {/* <div className="text-center mb-5">
          <h1
            className={`fs-40 fw-600 w-75 mx-auto text-theme ${
              isVisible
                ? "animate__animated animate__zoomIn"
                : "animate__animated animate__fadeOut"
            }`}
          >
            Celebrate Our Opening with Exclusive Launch Offers for the first 50
            patients
          </h1>
        </div> */}
        {/* <div className="py-5" style={{ backgroundImage: `url(${landingPic})` }}>
          <div className="" style={{ maxWidth: "900px", margin: "auto" }}>
            <div className="p-5 bg-theme text-white">
              <h3>
                Check out the amazing offers below and choose the ones that
                capture your interest!
              </h3>
              <div className="form-check">
                <input
                  className="form-check-input"
                  type="checkbox"
                  value=""
                  id="flexCheckDefault"
                />
                <label className="form-check-label" for="flexCheckDefault">
                  £1500 of Full Arch Implants
                </label>
              </div>
              <div className="form-check">
                <input
                  className="form-check-input"
                  type="checkbox"
                  value=""
                  id="flexCheckChecked"
                />
                <label className="form-check-label" for="flexCheckChecked">
                  20% off all Invisalign treatments
                </label>
              </div>
              <div className="form-check">
                <input
                  className="form-check-input"
                  type="checkbox"
                  value=""
                  id="flexCheckChecked"
                />
                <label className="form-check-label" for="flexCheckChecked">
                  10% off Composite Bonding treatments
                </label>
              </div>
              <div className="form-check">
                <input
                  className="form-check-input"
                  type="checkbox"
                  value=""
                  id="flexCheckChecked"
                />
                <label className="form-check-label" for="flexCheckChecked">
                  10% off dental implants
                </label>
              </div>
              <div className="form-check">
                <input
                  className="form-check-input"
                  type="checkbox"
                  value=""
                  id="flexCheckChecked"
                />
                <label className="form-check-label" for="flexCheckChecked">
                  Over £100 off Boutique Teeth Whitening
                </label>
              </div>
              <div className="form-check">
                <input
                  className="form-check-input"
                  type="checkbox"
                  value=""
                  id="flexCheckChecked"
                />
                <label className="form-check-label" for="flexCheckChecked">
                  Free Philips Sonicare Toothbrush with Plan Memberships
                </label>
              </div>
              <div className="form-check">
                <input
                  className="form-check-input"
                  type="checkbox"
                  value=""
                  id="flexCheckChecked"
                />
                <label className="form-check-label" for="flexCheckChecked">
                  Free Tepes with Hygiene Appointments
                </label>
              </div>
              <p>
                ***Limited availability <br /> Add any promotional codes or
                additional information here to help us process your request.
              </p>
            </div>
            <div className="row mt-4">
              <div className="col-lg-4">
                <div className="form-floating mb-3">
                  <input
                    type="text"
                    className="form-control"
                    placeholder="Your name"
                  />
                  <label htmlFor="">Your name</label>
                </div>
              </div>
              <div className="col-lg-4">
                <div className="form-floating mb-3">
                  <input
                    type="email"
                    className="form-control"
                    id="floatingInput"
                    placeholder="name@example.com"
                  />
                  <label for="floatingInput">Email address</label>
                </div>
              </div>
              <div className="col-lg-4">
                <div className="form-floating mb-3">
                  <input
                    type="text"
                    className="form-control"
                    id="floatingInput"
                    placeholder="Telephone number"
                  />
                  <label htmlFor="">Telephone number</label>
                </div>
              </div>
              <div className="col-lg-12">
                <div className="form-floating mb-3">
                  <textarea
                    type="text"
                    rows={"3"}
                    className="form-control"
                    id="floatingInput"
                    placeholder="Your message"
                    style={{ height: "90px" }}
                  ></textarea>
                  <label htmlFor="">Your message</label>
                </div>
              </div>
            </div>
          </div>
        </div> */}
        <div
          className={`card bg-light mb-4 rounded-3 border-0 ${
            isVisible
              ? "animate__animated animate__zoomIn"
              : "animate__animated animate__fadeOut"
          }`}
        >
          <div className="card-body p-5">
            <div className="row align-items-center">
              <div className="col-lg-5 mb-3">
                <span class="badge bg-golden mb-3 rounded-pill px-4">
                  {" "}
                  Offer 1{" "}
                </span>
                <h2 className="fs-40 mt-3">
                  Free Philips Sonicare Electric Toothbrush
                </h2>
                <p>
                  Join our practice on a dental plan worth over £20 a month and
                  receive a free Philips Sonicare Toothbrush worth £69.99
                </p>
                <Link
                  to="/contact-us"
                  className="btn btn-theme rounded-pill fs-14 w-50 mt-4"
                >
                  Claim Now
                </Link>
              </div>
              <div className="col-lg-7">
                <img
                  src={offer_1}
                  className="img-fluid w-100 ps-0 ps-lg-5"
                ></img>
              </div>
            </div>
          </div>
        </div>

        <div
          className={`card bg-light mb-4 rounded-3 border-0 ${
            isVisible
              ? "animate__animated animate__zoomIn"
              : "animate__animated animate__fadeOut"
          }`}
        >
          <div className="card-body p-5">
            <div className="row align-items-center">
              <div className="col-lg-5 mb-3">
                <span class="badge bg-golden mb-3 rounded-pill px-4">
                  {" "}
                  Offer 2{" "}
                </span>
                <h2 className="fs-40 mt-3">
                  Over £10 worth of FREE Tepe Interdental Brushes with your
                  Hygiene Appointment
                </h2>
                <p>
                  Pop in to see our hygienist to make sure your teeth and gums
                  are in the best of health. Book in for a virtually pain free
                  with our start of the art warm water scaler and give you over
                  £10 of free Tepe Interdental Brushes to help you maintain your
                  oral health at home
                </p>
                <Link
                  to="/contact-us"
                  className="btn btn-theme rounded-pill fs-14 w-50 mt-4"
                >
                  Claim Now
                </Link>
              </div>
              <div className="col-lg-7">
                <img
                  src={offer_2}
                  className="img-fluid w-100 ps-0 ps-lg-5"
                ></img>
              </div>
            </div>
          </div>
        </div>

        <div
          className={`card bg-light mb-4 rounded-3 border-0 ${
            isVisible
              ? "animate__animated animate__zoomIn"
              : "animate__animated animate__fadeOut"
          }`}
        >
          <div className="card-body p-5">
            <div className="row align-items-center">
              <div className="col-lg-5 mb-3">
                <span class="badge bg-golden mb-3 rounded-pill px-4">
                  {" "}
                  Offer 3{" "}
                </span>
                <h2 className="fs-40 mt-3">
                  Save over £100 on Boutique Tooth Whitening
                </h2>
                <p>
                  If you need to give your smile a new lease of life then teeth
                  whitening could be for you. Book in now to see if your
                  eligible for teeth whitening at only £195!
                </p>
                <Link
                  to="/contact-us"
                  className="btn btn-theme rounded-pill fs-14 w-50 mt-4"
                >
                  Claim Now
                </Link>
              </div>
              <div className="col-lg-7">
                <img
                  src={offer_3}
                  className="img-fluid w-100 ps-0 ps-lg-5"
                ></img>
              </div>
            </div>
          </div>
        </div>

        <div
          className={`card bg-light mb-4 rounded-3 border-0 ${
            isVisible
              ? "animate__animated animate__zoomIn"
              : "animate__animated animate__fadeOut"
          }`}
        >
          <div className="card-body p-5">
            <div className="row align-items-center">
              <div className="col-lg-5 mb-3">
                <span class="badge bg-golden mb-3 rounded-pill px-4">
                  {" "}
                  Offer 4{" "}
                </span>
                <h2 className="fs-40 mt-3">
                  20% off ALL Invisalign&#174; Treatments
                </h2>
                <p>
                  If you’ve been wanting to straighten your teeth for a while
                  then there is no better time to start than now. Book in for a
                  free consultation and claim 20% off your treatment.
                </p>
                <a
                  className="btn btn-theme rounded-pill fs-14 w-50 mt-4"
                  href="https://www.invisalign-ilkley.com/"
                  target="_blank"
                >
                  Claim Now
                </a>
              </div>
              <div className="col-lg-7">
                <img
                  src={offer_4}
                  className="img-fluid w-100 ps-0 ps-lg-5"
                ></img>
              </div>
            </div>
          </div>
        </div>

        <div
          className={`card bg-light mb-4 rounded-3 border-0 ${
            isVisible
              ? "animate__animated animate__zoomIn"
              : "animate__animated animate__fadeOut"
          }`}
        >
          <div className="card-body p-5">
            <div className="row align-items-center">
              <div className="col-lg-5 mb-3">
                <span class="badge bg-golden mb-3 rounded-pill px-4">
                  {" "}
                  Offer 5{" "}
                </span>
                <h2 className="fs-46 mt-3">
                  10% off dental implant treatments
                </h2>
                <p>
                  If you’ve lost a tooth or two and been wanting to “fill the
                  gap” then book in for a free consultation and claim exclusive
                  discounts as part of our launch
                </p>
                <a
                  href="https://dentalimplants-ilkley.com"
                  target="_blank"
                  className="btn btn-theme rounded-pill fs-14 w-50 mt-4"
                >
                  Claim Now
                </a>
              </div>
              <div className="col-lg-7">
                <img
                  src={offer_5}
                  className="img-fluid w-100 ps-0 ps-lg-5"
                ></img>
              </div>
            </div>
          </div>
        </div>

        <div
          className={`card bg-light mb-4 rounded-3 border-0 ${
            isVisible
              ? "animate__animated animate__zoomIn"
              : "animate__animated animate__fadeOut"
          }`}
        >
          <div className="card-body p-5">
            <div className="row align-items-center">
              <div className="col-lg-5 mb-3">
                <span class="badge bg-golden mb-3 rounded-pill px-4">
                  {" "}
                  Offer 6{" "}
                </span>
                <h2 className="fs-46 mt-3">
                  Save £1,500 on a Same Day Smile with Full Arch Implants
                </h2>
                <p>
                  If you struggle to eat and smile or just tired of your
                  dentures moving around then Full Arch treatment could be for
                  you with a free consultation to see if the treatment could be
                  an option for you.
                </p>
                <a
                  href="https://samedaysmile-ilkley.com"
                  target="_blank"
                  className="btn btn-theme rounded-pill fs-14 w-50 mt-4"
                >
                  Claim Now
                </a>
              </div>
              <div className="col-lg-7">
                <img
                  src={offer_5}
                  className="img-fluid w-100 ps-0 ps-lg-5"
                ></img>
              </div>
            </div>
          </div>
        </div>

        <div
          className={`card bg-light mb-4 rounded-3 border-0 ${
            isVisible
              ? "animate__animated animate__zoomIn"
              : "animate__animated animate__fadeOut"
          }`}
        >
          <div className="card-body p-5">
            <div className="row align-items-center">
              <div className="col-lg-5 mb-3">
                <span class="badge bg-golden mb-3 rounded-pill px-4">
                  {" "}
                  Offer 7{" "}
                </span>
                <h2 className="fs-46 mt-3">
                  Perfect your smile with 10% off all Composite Bonding
                  Treatments
                </h2>
                <p>
                  If you’ve got a few chips that need taken care of or want to
                  give your straight teeth the perfect shape then book a free
                  consultation to see how composite bonding can help you.
                </p>
                <a
                  href="https://compositebonding-ilkley.com"
                  target="_blank"
                  className="btn btn-theme rounded-pill fs-14 w-50 mt-4"
                >
                  Claim Now
                </a>
              </div>
              <div className="col-lg-7">
                <img
                  src={offer_5}
                  className="img-fluid w-100 ps-0 ps-lg-5"
                ></img>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default HeroSection;
