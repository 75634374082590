import React, { useEffect, useRef, useState } from 'react';
import 'animate.css';
import { Swiper, SwiperSlide } from 'swiper/react';
import "animate.css";
import "swiper/css";
import "swiper/css/navigation";
import "swiper/css/autoplay";
import { Navigation, Pagination, Autoplay } from "swiper/modules";


import img1 from '../../assets/img/smiles/34.png';
import img2 from '../../assets/img/smiles/35.png';
import img3 from '../../assets/img/smiles/36.png';
import img4 from '../../assets/img/smiles/37.png';
import img5 from '../../assets/img/smiles/38.png';
import img6 from '../../assets/img/smiles/39.png';
import img7 from '../../assets/img/smiles/40.png';
import img8 from '../../assets/img/smiles/41.png';
import img9 from '../../assets/img/smiles/42.png';
import img10 from '../../assets/img/smiles/43.png';
import img11 from '../../assets/img/smiles/44.png';
import img12 from '../../assets/img/smiles/45.png';
import img13 from '../../assets/img/smiles/46.png';
import img14 from '../../assets/img/smiles/47.png';
import img15 from '../../assets/img/smiles/48.png';

import _img1 from '../../assets/img/instagram/69.png';
import _img2 from '../../assets/img/instagram/70.png';
import _img3 from '../../assets/img/instagram/71.png';
import _img4 from '../../assets/img/instagram/72.png';
import _img5 from '../../assets/img/instagram/73.png';
import _img6 from '../../assets/img/instagram/74.png';
import _img7 from '../../assets/img/instagram/75.png';
import _img8 from '../../assets/img/instagram/76.png';
import _img9 from '../../assets/img/instagram/77.png';
import _img10 from '../../assets/img/instagram/78.png';
import _img11 from '../../assets/img/instagram/79.png';
import _img12 from '../../assets/img/instagram/80.png';
import _img13 from '../../assets/img/instagram/81.png';
import _img14 from '../../assets/img/instagram/82.png';

const TreatmentOptions = () => {
  const [isVisible, setIsVisible] = useState(false);
  const ref = useRef();

  const images = [
    img1, img2, img3, _img1, _img2, _img3, img4, img5, img6, _img4, _img5, _img6,
    img7, img8, img9, _img7, _img8, _img9, img10, img11, img12, _img10, _img11,
    _img12, img13, img14, img15, _img13, _img14,
  ];

  const handleScroll = (entries) => {
    entries.forEach((entry) => {
      if (entry.isIntersecting) {
        setIsVisible(true);
      } else {
        setIsVisible(false);
      }
    });
  };

  useEffect(() => {
    const observer = new IntersectionObserver(handleScroll, {
      threshold: 0.1, // Adjust threshold as needed
    });
    if (ref.current) {
      observer.observe(ref.current);
    }

    return () => {
      if (ref.current) {
        observer.unobserve(ref.current);
      }
    };
  }, []);

  return (
    <div ref={ref} className="row p-5">
      <div className="col-12">
        <div className="container d-flex justify-content-center">
          <div className="text-center mb-5">
            <h1 className="text-theme animate__animated animate__zoomIn">
                Just a few of the <span className='text-golden'> happy patients </span> we’ve treated
            </h1>
            <p>Our team have been transforming smiles for decades - here are just some of the smiles we’ve transformed</p>
          </div>
        </div>
      </div>
      <div className="col-12">
        <div className="container">
          <Swiper
            modules={[Navigation, Pagination, Autoplay]}
            spaceBetween={30}
            slidesPerView={3}
            loop={true}
            autoplay={{
                delay: 3000,
                disableOnInteraction: false,
            }}
            navigation
            breakpoints={{
                640: { slidesPerView: 1 },
                768: { slidesPerView: 2 },
                1024: { slidesPerView: 3 },
            }}
          >
            {images.map((img, index) => (
              <SwiperSlide key={index}>
                <div
                  className={`card d-flex flex-column h-100 border-golden rounded ${
                    isVisible
                      ? 'animate__animated animate__zoomIn'
                      : 'animate__animated animate__zoomOut'
                  }`}
                >
                  <img
                    className="card-img-top img-fluid"
                    src={img}
                    alt={`Gallery Image ${index}`}
                  />
                </div>
              </SwiperSlide>
            ))}
          </Swiper>
        </div>
      </div>
    </div>
  );
};

export default TreatmentOptions;
