import { Helmet } from "react-helmet";
import Navbar from "../components/offer-page/navbar";
import Header from "../components/offer-page/hero";
import Offers from "../components/offer-page/offers";
import Mission from "../components/offer-page/mission";
import Gallery from "../components/offer-page/gallery";
import Testimonials from "../components/universal/testimonials";
import Footer from "../components/universal/footer";
import OnlyMap from "../components/universal/only-map";
import ContactSection from "../components/offer-page/contact-section";

function Index() {
  return (
    <div>
      <Helmet>
        <title>Local Dentist in Ilkley, West Yorkshire - Special Offer </title>
      </Helmet>
      <Navbar />
      <Header />
      <ContactSection />
      <Offers />
      <Mission />
      <Gallery />
      <Testimonials />
      <OnlyMap />
      {/* <Contact /> */}
      <Footer />
    </div>
  );
}

export default Index;
