import landingPic from "../../assets/img/landing-contact-bg.webp";
import React, { useEffect, useRef, useState } from "react";
import "animate.css";
import "../../assets/css/contact-section.css";
import emailjs from "emailjs-com";
import "bootstrap/dist/css/bootstrap.min.css";
import { Link } from "react-router-dom";
const ContactSection = () => {
  const [isVisible, setIsVisible] = useState(false);
  const [buttonText, setButtonText] = useState("Send Message");
  const [alertMessage, setAlertMessage] = useState("");
  const [alertType, setAlertType] = useState("");
  const [showModal, setShowModal] = useState(false);
  const [showAlert, setShowAlert] = useState(false);
  const [selectedOffers, setSelectedOffers] = useState([]);
  const [error, setError] = useState(false);
  const sectionRef = useRef(null);

  useEffect(() => {
    const observer = new IntersectionObserver(
      (entries) => {
        entries.forEach((entry) => {
          if (entry.isIntersecting) {
            setIsVisible(true);
          } else {
            setIsVisible(false); // Set to false when the section is not visible
          }
        });
      },
      { threshold: 0.1 } // Adjust the threshold as needed
    );

    if (sectionRef.current) {
      observer.observe(sectionRef.current);
    }

    return () => {
      if (sectionRef.current) {
        observer.unobserve(sectionRef.current);
      }
    };
  }, []);
  const offers = [
    "£1500 of Full Arch Implants",
    "20% off all Invisalign treatments",
    "10% off Composite Bonding treatments",
    "10% off dental implants",
    "Over £100 off Boutique Teeth Whitening",
    "Free Philips Sonicare Toothbrush with Plan Memberships",
    "Free Tepes with Hygiene Appointments",
  ];
  const handleCheckboxChange = (event) => {
    const { value, checked } = event.target;

    setSelectedOffers((prev) =>
      checked ? [...prev, value] : prev.filter((offer) => offer !== value)
    );
  };
  const handleSubmit = (e) => {
    e.preventDefault();
    setButtonText("Sending...");
    console.log(e.target);
    if (selectedOffers.length === 0) {
      setError(true);
      setButtonText("Send Message");
    } else {
      setError(false);
      emailjs
        .sendForm(
          "service_2cnqd96",
          "template_wtypbnl",
          e.target,
          "nQBJzdhm_0rQ4QbLv"
        )
        .then(
          (result) => {
            setAlertMessage(
              "Thank you for contacting, one of our team will be in touch soon!"
            );
            setAlertType("success");
            // setShowAlert(true);
            e.target.reset();
            setButtonText("Send Message");
            setShowModal(true);
          },
          (error) => {
            setAlertMessage("Failed to send message. Please try again.");
            setAlertType("danger");
            setShowAlert(true);
            alert("Error during form submission:", error);
            setButtonText("Send Message");
          }
        );
    }
  };

  return (
    <section ref={sectionRef} className="py-5">
      <div className="text-center mb-5">
        <div className="container">
          <h1
            className={`fs-40 fw-600 w-75 mx-auto text-theme ${
              isVisible
                ? "animate__animated animate__zoomIn"
                : "animate__animated animate__fadeOut"
            }`}
          >
            Celebrate Our Opening with Exclusive Launch Offers for the first 50
            patients
          </h1>
        </div>
      </div>
      {showAlert && (
        <div
          className={`alert alert-${alertType} alert-dismissible fade show`}
          role="alert"
        >
          {alertMessage}
          <button
            type="button"
            className="btn-close"
            onClick={() => setShowAlert(false)}
          ></button>
        </div>
      )}
      <div
        className="py-5 contact position-relative"
        style={{ backgroundImage: `url(${landingPic})` }}
      >
        <div
          className="position-relative z-1"
          style={{ maxWidth: "900px", margin: "auto" }}
        >
          <form onSubmit={handleSubmit}>
            <div className="p-5 bg-theme text-white">
              <h3>
                Check out the amazing offers below and choose the ones that
                capture your interest!
              </h3>
              {offers.map((offer, index) => (
                <div className="form-check" key={index}>
                  <input
                    className="form-check-input"
                    type="checkbox"
                    name="offer"
                    value={offer}
                    id={`checkbox-${index}`}
                    onChange={handleCheckboxChange}
                  />
                  <label
                    className="form-check-label"
                    htmlFor={`checkbox-${index}`}
                  >
                    {offer}
                  </label>
                </div>
              ))}
              {error && (
                <p style={{ color: "red" }}>
                  Please select at least one offer.
                </p>
              )}
              <p className="mt-3">*Terms and conditions apply</p>
            </div>
            <div className="row mt-4">
              <div className="col-lg-4">
                <div className="form-floating mb-3">
                  <input
                    type="text"
                    className="form-control"
                    placeholder="Your name"
                    name="name"
                    required
                  />
                  <label htmlFor="">Your name</label>
                </div>
              </div>
              <div className="col-lg-4">
                <div className="form-floating mb-3">
                  <input
                    name="email"
                    id="email"
                    className="form-control"
                    placeholder="name@example.com"
                    pattern="[^@]+@[^@]+\.[a-zA-Z]{2,6}"
                    title="email format should be correct"
                    required
                  />
                  <label for="floatingInput">Email address</label>
                </div>
              </div>
              <div className="col-lg-4">
                <div className="form-floating mb-3">
                  <input
                    type="text"
                    className="form-control"
                    name="phone_number"
                    title="Phone number must be 11 digits"
                    id="phoneNumber"
                    pattern="[0-9]{11}"
                    placeholder="Telephone number"
                    required
                  />
                  <label htmlFor="">Telephone number</label>
                </div>
              </div>
              <div className="col-lg-12">
                <div className="form-floating mb-3">
                  <textarea
                    type="text"
                    name="message"
                    id="message"
                    rows="3"
                    className="form-control"
                    placeholder="Your message"
                    style={{ height: "90px" }}
                    required
                  ></textarea>
                  <label htmlFor="">Your message</label>
                </div>
              </div>
            </div>
            <div className="position-relative z-1 text-white d-flex gap-3 align-items-center">
              <p>
                On submitting this form you consent to Regent Dental collecting
                your personal data. For more information please read our{" "}
                <Link style={{ color: "white" }} to={"/privacy-policy"}>
                  privacy policy
                </Link>
                .
              </p>
              <div>
                <button
                  className={`btn btn-theme animate__animated ${
                    isVisible ? "animate__fadeIn" : "animate__fadeOut"
                  }`}
                >
                  {buttonText}
                </button>
              </div>
            </div>
          </form>
        </div>
      </div>
      {/* Success Modal */}
      {showModal && (
        <div
          className="modal d-block border-0"
          style={{ backgroundColor: "rgba(0, 0, 0, 0.8)" }}
        >
          <div className="modal-dialog modal-dialog-centered">
            <div className="modal-content bg-success text-white">
              <div className="modal-header bg-theme d-flex justify-content-end border-0">
                <a type="button" onClick={() => setShowModal(false)}>
                  <i class="fa fa-times text-white" aria-hidden="true"></i>
                </a>
              </div>
              <div className="modal-body bg-theme p-4 text-center">
                <p className="fs-20">
                  <span className="text-golden">Thank you</span> for getting in
                  touch, one of the team will be in touch soon!
                </p>
              </div>
            </div>
          </div>
        </div>
      )}
    </section>
  );
};

export default ContactSection;
