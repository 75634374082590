const OnlyMap = () => {
  return (
    <div style={{ minHeight: "50vh" }}>
      <iframe
        title="map"
        src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d2349.365759136127!2d-1.8295329230041755!3d53.92524553125128!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x487bfae3ed12ed73%3A0xb201e565462d5dc8!2sRegent%20Orthodontics!5e0!3m2!1sen!2s!4v1729688462823!5m2!1sen!2s"
        width="100%"
        height="100%"
        style={{ border: 0 }}
        allowFullScreen=""
        loading="lazy"
        referrerPolicy="no-referrer-when-downgrade"
      ></iframe>
    </div>
  );
};

export default OnlyMap;
