import React, { useState } from "react";
import { useCookies } from "react-cookie";

const CookieBanner = () => {
    const [cookies, setCookie] = useCookies(["cookieConsent"]);
    const [isVisible, setIsVisible] = useState(!cookies.cookieConsent);

    const acceptCookies = () => {
        setCookie("cookieConsent", true, { path: "/", maxAge: 31536000 });
        setIsVisible(false);
    };

    if (!isVisible) return null;

    return (
        <div className="banner  bg-dark">
            <p className="fs-12 text-center mt-3 ">
                We use cookies to improve your experience. By continuing, you agree to
                our use of cookies.
            </p>
            <button onClick={acceptCookies} className="btn btn-golden fs-12" style={{height: '35px', width: '150px'}}>
                Accept
            </button>
            <a href="/privacy-policy" className="btn btn-golden fs-12" style={{height: '35px', width: '150px'}}>
                Cookies Policy
            </a>
        </div>
    );
};

export default CookieBanner;
