import React, { useState, useEffect } from 'react';
import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';
import emailjs from 'emailjs-com';

const LeadGenerationModal = () => {
    const [modalShow, setModalShow] = useState(false);
    const [alertMessage, setAlertMessage] = useState('');
    const [alertType, setAlertType] = useState('');
    const [showAlert, setShowAlert] = useState(false);
    const [mobileNumber, setMobileNumber] = useState('');
    const [error, setError] = useState('');
    const [isFormSubmitted, setIsFormSubmitted] = useState(false);
    const [buttonText, setButtonText] = useState('Send Message');
    const [titleText, setTitleText] = useState('Bringing Brighter Smiles to Ilkley – Coming Soon!');

    const handleMobileNumberChange = (e) => {
        const value = e.target.value;
        setMobileNumber(value);
        setError('');
    };

    const validateMobileNumber = () => {
        const ukMobileRegex = /^0\d{10}$/;

        if (!ukMobileRegex.test(mobileNumber)) {
            setError('Please enter a valid 11 digit UK mobile number.');
        }
    };

    useEffect(() => {
        setModalShow(true); // Show modal on page load
    }, []);

    const closeModal = () => {
        setModalShow(false);
        setShowAlert(false); // Hide alert if modal is closed
        setIsFormSubmitted(false); // Reset form submission state when modal is closed
    };

    const handleSubmit = (e) => {
        e.preventDefault();
        setButtonText('Sending...');

        emailjs.sendForm('service_2cnqd96', 'template_qh5hcrp', e.target, 'nQBJzdhm_0rQ4QbLv')
            .then((result) => {
                setAlertMessage('Thank you for registering, one of our team will be in touch soon!');
                setAlertType('success');
                // setShowAlert(true);
                setIsFormSubmitted(true);
                setButtonText('Send Message');
                setTitleText(' ');
            }, (error) => {
                setAlertMessage('Failed to send message. Please try again.');
                setAlertType('danger');
                setButtonText('Send Message');
                setShowAlert(true);
            });
    };

    return (
        <>
            <Modal
                show={modalShow}
                onHide={closeModal}
                size=""
                aria-labelledby="contained-modal-title-vcenter"
                centered
            >
                <Modal.Body className="bg-dark p-5">
                    <div className='d-flex justify-content-between mb-3 position-relative'>
                        <div><h4 className='text-white'>{titleText}</h4></div>
                        <div className='lead-generation-close-btn'><a type='button' onClick={closeModal}><img src='https://img.icons8.com/ios-glyphs/20/FFFFFF/delete-sign.png' alt="close"></img></a></div>
                    </div>
                    {showAlert && (
                        <div className={`alert alert-${alertType} alert-dismissible fade show`} role="alert">
                            {alertMessage}
                            <button type="button" className="btn-close" onClick={() => setShowAlert(false)}></button>
                        </div>
                    )}
                    {isFormSubmitted ? (
                        <div className="text-center text-white">
                            <h5>{alertMessage}</h5>
                        </div>
                    ) : (
                        <form onSubmit={handleSubmit}>
                            <div className='p-3'>
                                <div className="mb-3">
                                    <input type="text" name="name" className="form-control rounded-0" placeholder="Name*" required />
                                </div>
                                <div className="mb-3">
                                    <input type="email" name="email" className="form-control rounded-0" placeholder="Email Address*" required />
                                </div>
                                <div className="mb-3">
                                    <input type="tel" name="phone_number" className="form-control rounded-0" placeholder="Phone Number*" required pattern="\d{11}" title="Phone number must be 11 digits" />
                                    {error && <small className="text-danger">{error}</small>}
                                </div>
                                <div className="mb-3">
                                    <div className="form-check">
                                        <input className="form-check-input" type="checkbox" required />
                                        <label className="form-check-label text-light fs-12"> Please tick this box to agree to our <a href="/privacy-policy" target='_blank'> Privacy Policy.</a> </label>
                                    </div>
                                    <div className="form-check">
                                        <input className="form-check-input" type="checkbox" required />
                                        <label className="form-check-label text-light fs-12"> Please tick this box to confirm you are happy to receive information and offers from Regent Dental </label>
                                    </div>
                                </div>
                                <Button className='btn btn-modal rounded-0 w-100' type="submit">
                                    {buttonText}
                                </Button>
                            </div>
                        </form>
                    )}
                </Modal.Body>
            </Modal>
        </>
    );
};

export default LeadGenerationModal;
