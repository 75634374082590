import React, { useEffect, useRef, useState } from 'react';
import 'animate.css'; // Make sure you have animate.css installed

const PricingPlans = () => {
    const [isVisible, setIsVisible] = useState(false);
    const sectionRef = useRef(null);

    useEffect(() => {
        const observer = new IntersectionObserver(
            (entries) => {
                entries.forEach(entry => {
                    if (entry.isIntersecting) {
                        setIsVisible(true);
                    } else {
                        setIsVisible(false); // Set to false when the section is not visible
                    }
                });
            },
            { threshold: 0.1 } // Adjust the threshold as needed
        );

        if (sectionRef.current) {
            observer.observe(sectionRef.current);
        }

        return () => {
            if (sectionRef.current) {
                observer.unobserve(sectionRef.current);
            }
        };
    }, []);

    return (
        <div className="container mb-5" ref={sectionRef}>
            <div className="mt-5">
                <div className="text-center">
                    <h1 className={`fs-50`}>
                        Our Pricing Plans
                    </h1>
                </div>
                <div className="row mt-5">
                    {/* Card 1 */}
                    <div className="col-md-12 mb-4">
                        <div className={`card border-golden`}>
                            <div className="card-body p-4">
                                <div className="text-center">
                                    <h4>Examination & Hygiene</h4>
                                </div>
                                <div className="table-responsive mt-4">
                                    <table className="table">
                                        <tbody>
                                            <tr>
                                                <th scope="row" width="75%">New Patient Examination (includes bitewings and 3D intraoral scan)</th>
                                                <td className='text-end'>£75</td>
                                            </tr>
                                            <tr>
                                                <th scope="row" width="75%">Routine Examination</th>
                                                <td className='text-end'>£50</td>
                                            </tr>
                                            <tr>
                                                <th scope="row" width="75%">Hygiene (30 min) - Including Airflow</th>
                                                <td className='text-end'>£90</td>
                                            </tr>
                                            <tr>
                                                <th scope="row" width="75%"> Periodontal Treatment (gum disease)</th>
                                                <td className='text-end'>From £135</td>
                                            </tr>
                                            <tr>
                                                <th scope="row" width="75%">Emergency Appointment (Assessment only)</th>
                                                <td className='text-end'>From £100</td>
                                            </tr>
                                        </tbody>
                                    </table>
                                </div>
                            </div>
                        </div>
                    </div>

                    {/* Card 2 */}
                    <div className="col-md-12 mb-4">
                        <div className={`card border-golden ${isVisible ? 'animate__animated animate__zoomIn' : 'animate__animated animate__fadeOut'}`}>
                            <div className="card-body p-4">
                                <div className="text-center">
                                    <h4>Restorative Dentistry</h4>
                                </div>
                                <div className="table-responsive mt-4">
                                    <table className="table">
                                        <tbody>
                                            <tr>
                                                <th scope="row" width="75%">Composite Fillings</th>
                                                <td className='text-end'>From £149</td>
                                            </tr>
                                            <tr>
                                                <th scope="row" width="75%">Crown</th>
                                                <td className='text-end'>From £700</td>
                                            </tr>
                                            <tr>
                                                <th scope="row" width="75%">Bridge (Per Unit)</th>
                                                <td className='text-end'>From £700</td>
                                            </tr>
                                            <tr>
                                                <th scope="row" width="75%">Inlay/Onlay</th>
                                                <td className='text-end'>From £700</td>
                                            </tr>
                                            <tr>
                                                <th scope="row" width="75%">Porcelain Veneers</th>
                                                <td className='text-end'>From £750</td>
                                            </tr>
                                            <tr>
                                                <th scope="row" width="75%">Root Canal Treatment</th>
                                                <td className='text-end'>From £400</td>
                                            </tr>
                                            <tr>
                                                <th scope="row" width="75%">Dentures Partial Acrylic</th>
                                                <td className='text-end'>From £525</td>
                                            </tr>
                                            <tr>
                                                <th scope="row" width="75%">Dentures Full Acrylic (upper or lower)</th>
                                                <td className='text-end'>From £795</td>
                                            </tr>
                                            <tr>
                                                <th scope="row" width="75%">Nightguard</th>
                                                <td className='text-end'>£125</td>
                                            </tr>
                                        </tbody>
                                    </table>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div className="col-md-12 mb-4">
                        <div className={`card border-golden ${isVisible ? 'animate__animated animate__zoomIn' : 'animate__animated animate__fadeOut'}`}>
                            <div className="card-body p-4">
                                <div className="text-center">
                                    <h4>Oral Surgery</h4>
                                </div>
                                <div className="table-responsive mt-4">
                                    <table className="table">
                                        <tbody>
                                            <tr>
                                                <th scope="row" width="75%">Extractions</th>
                                                <td className='text-end'>From £125</td>
                                            </tr>
                                            <tr>
                                                <th scope="row" width="75%">Complex Extraction</th>
                                                <td className='text-end'>From £250</td>
                                            </tr>
                                            <tr>
                                                <th scope="row" width="75%">Wisdom Tooth Extraction</th>
                                                <td className='text-end'>From £375</td>
                                            </tr>
                                            <tr>
                                                <th scope="row" width="75%">IV Sedation</th>
                                                <td className='text-end'>From £350</td>
                                            </tr>
                                        </tbody>
                                    </table>
                                </div>
                            </div>
                        </div>
                    </div>

                    
                    {/* Card 3 */}
                    <div className="col-md-12 mb-4">
                        <div className={`card border-golden ${isVisible ? 'animate__animated animate__zoomIn' : 'animate__animated animate__fadeOut'}`}>
                            <div className="card-body p-4">
                                <div className="text-center">
                                    <h4>Teeth Straightening</h4>
                                </div>
                                <div className="table-responsive mt-4">
                                    <table className="table">
                                        <tbody>
                                            <tr class="">
                                                <th scope="row" width="75%">Consultation</th>
                                                <td className='text-end'>£100</td>
                                            </tr>
                                            <tr class="">
                                                <th scope="row" width="75%">Invisalign® Clear Aligners</th>
                                                <td className='text-end'>From £2,250</td>
                                            </tr>
                                            <tr class="">
                                                <th scope="row" width="75%">NiTime Clear Aligners</th>
                                                <td className='text-end'>From £2,750</td>
                                            </tr>
                                            <tr class="">
                                                <th scope="row" width="75%">Vivera Retainers</th>
                                                <td className='text-end'>From £400</td>
                                            </tr>
                                        </tbody>
                                    </table>
                                </div>
                            </div>
                        </div>
                    </div>

                    {/* Card 4 */}
                    <div className="col-md-12 mb-4">
                        <div className={`card border-golden ${isVisible ? 'animate__animated animate__zoomIn' : 'animate__animated animate__fadeOut'}`}>
                            <div className="card-body p-4">
                                <div className="text-center">
                                    <h4>Composite Bonding</h4>
                                </div>
                                <div className="table-responsive mt-4">
                                    <table className="table">
                                        <tbody>
                                            <tr class="">
                                                <th scope="row" width="75%">Mock up (per tooth)</th>
                                                <td className='text-end'>£50</td>
                                            </tr>
                                            <tr class="">
                                                <th scope="row" width="75%">Composite Veneer (per tooth)</th>
                                                <td className='text-end'>£350</td>
                                            </tr>
                                        </tbody>
                                    </table>
                                </div>
                            </div>
                        </div>
                    </div>

                    {/* Card 5 */}
                    <div className="col-md-12 mb-4">
                        <div className={`card border-golden ${isVisible ? 'animate__animated animate__zoomIn' : 'animate__animated animate__fadeOut'}`}>
                            <div className="card-body p-4">
                                <div className="text-center">
                                    <h4>Dental Implants</h4>
                                </div>
                                <div className="table-responsive mt-4">
                                    <table className="table">
                                        <tbody>
                                            <tr class="">
                                                <th scope="row" width="75%">Consultation</th>
                                                <td className='text-end'>£100</td>
                                            </tr>
                                            <tr class="">
                                                <th scope="row" width="75%">Dental Implants with Crown</th>
                                                <td className='text-end'>From £2,795</td>
                                            </tr>
                                            <tr class="">
                                                <th scope="row" width="75%">Full Arch Same Day Implants (per arch)</th>
                                                <td className='text-end'>From £13,995</td>
                                            </tr>
                                        </tbody>
                                    </table>
                                </div>
                            </div>
                        </div>
                    </div>

                    {/* Card 6 */}
                    <div className="col-md-12 mb-4">
                        <div className={`card border-golden ${isVisible ? 'animate__animated animate__zoomIn' : 'animate__animated animate__fadeOut'}`}>
                            <div className="card-body p-4">
                                <div className="text-center">
                                    <h4>Tooth Whitening</h4>
                                </div>
                                <div className="table-responsive mt-4">
                                    <table className="table">
                                        <tbody>
                                            <tr class="">
                                                <th scope="row" width="75%">Boutique Whitening</th>
                                                <td className='text-end'>£295</td>
                                            </tr>
                                            <tr class="">
                                                <th scope="row" width="75%">Enlighten Whitening</th>
                                                <td className='text-end'>£595</td>
                                            </tr>
                                        </tbody>
                                    </table>
                                </div>
                            </div>
                        </div>
                    </div>


                    <div className="col-md-12 mb-4 mt-5">
                        <div className="d-flex justify-content-center">
                            <button className={`btn btn-theme ${isVisible ? 'animate__animated animate__zoomIn' : 'animate__animated animate__fadeOut'}`}>
                                Get in touch
                            </button>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default PricingPlans;
